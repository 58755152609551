@use "~styles" as *;

.presentation-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: $width-home-content-sm;
  margin: 0 1em;
  padding: 2.5em 0;
  gap: 1.5em;

  @include sm {
    flex-direction: column;
  }

  @include md {
    max-width: none;
    width: 85%;
    gap: 1em;
    padding: em(70) 0;
  }

  @include xl {
    max-width: $width-home-content-xl;
    padding: em(100) 0;
    gap: em(50);
  }

  &__image-container {
    flex: 1;
    position: relative;
    display: inline-block;
    width: 100%;

    @include md {
      width: em(404);
    }

    @include xl {
      width: em(568);
    }
  }

  &__text-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0.5em 0;
    gap: 1em;

    @include xl {
      gap: 3em;
    }

    &__block {
      display: flex;
      flex-direction: column;
      background-color: $blue-100;
      border-radius: 0.5em;
      padding: 1em;

      @include mdxl {
        padding: 1.5em 2em;
      }

      &__title {
        margin: 0;

        @extend %h3-size;
        @extend %h3-weight;
      }

      &__paragraphe {
        margin: 1em 0;
      }

      &__link-text {
        text-decoration: none;
      }
    }
  }
}
