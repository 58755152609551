@use "~styles" as *;

.herosection {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $blue-500;
  color: $white;
  margin-top: em(-50);
  padding-top: em(50);
  padding-bottom: em(40);

  @include mdxl {
    margin-top: em(-82);
    padding-top: em(82);
    padding-bottom: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: $width-home-content-sm;
    margin: 0 em(16);

    @include md {
      max-width: none;
      width: 85%;
      margin: 0;
    }

    @include xl {
      min-width: $width-home-content-xl;
      margin: 0;
    }
  }

  &__title {
    text-align: center;
    margin: 1em;
    max-width: 12em;

    @include mdxl {
      margin: 1.5em;
      max-width: none;
    }
  }

  &__subtitle {
    text-align: center;
    margin: 0;
    max-width: 19em;

    @include mdxl {
      max-width: 22em;
    }
  }

  &__search-bar {
    margin: 1em 0;
  }

  &__arrow-link {
    &__text {
      margin: 0;
      @extend %small-cta-size;
    }
  }

  &__discover-row {
    display: none;
    width: 100%;

    @include mdxl {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &__image {
      background-size: contain;
      display: inline-block;
      position: relative;

      &--left {
        @include md {
          width: em(321);
        }
        @include xl {
          width: em(343);
        }
      }

      &--right {
        @include md {
          width: em(290);
        }

        @include xl {
          width: em(311);
        }
      }
    }

    &__mid-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      text-decoration: none;
      transition: opacity 0.2s ease-in-out;
    }

    &__text {
      margin: 0;
      color: $blue-cyan;
      text-align: center;
      @extend %small-cta-size;
    }

    &__down-arrow {
      position: relative;
      display: inline-block;
      width: 1em;
      margin: 0.5em 0 0.75em;
      filter: $blue-cyan-filter;
      transform: rotate(90deg);
    }
  }
}
