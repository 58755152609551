@use "~styles" as *;

.mobileapp-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $blue-200;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: $width-home-content-sm;
    margin: 0 1em;
    padding: 1.5em 0 2.5em;
    gap: 1.5em;

    @include md {
      width: 85%;
    }

    @include mdxl {
      flex-direction: row-reverse;
      max-width: none;
      padding: em(120) 0;
      gap: 1em;
    }

    @include xl {
      max-width: $width-home-content-xl;
    }

    &__features {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1.5em;

      &__title-container {
        display: none;
        width: 100%;

        @include mdxl {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &__title {
          margin: 0;
          max-width: rem(350);
          @include mdxl {
            max-width: rem(400);
          }
        }

        &__arrow-container {
          position: relative;

          @include mdxl {
            width: em(101);
            display: block !important;
          }

          @media (max-width: 1040px) {
            display: none !important;
          }
        }
      }

      &__block {
        display: flex;
        flex-direction: column;
        background-color: $white;
        border-radius: 0.5em;
        padding: 1em;

        @include mdxl {
          padding: 1.5em 2em;
        }

        &__title {
          margin: 0;
        }

        &__paragraphe {
          margin: 1em 0 0;
        }
      }
    }

    &__images {
      flex: 1;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      gap: 1em;

      @include mdxl {
        flex-direction: column;
        gap: 3em;
      }

      &__badge-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1em;

        @include mdxl {
          gap: em(30);
        }
      }

      &__badge {
        position: relative;
        display: inline-block;
        width: em(137);

        @include mdxl {
          width: em(172);
        }
      }

      &__mobile-title {
        @include mdxl {
          display: none;
        }
      }
    }

    &__image-container {
      position: relative;
      width: 100%;

      @include md {
        width: em(370);
      }

      @include xl {
        width: em(470);
      }
    }
  }
}
