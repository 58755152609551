@use "sass:color";
@use "./design_tokens" as *;

@function rem($px) {
  @return #{$px / 16}rem;
}

@function em($px) {
  @return #{$px / 16}em;
}

// width thresholds
$max-width-content-sm: 50em;
$max-width-content-xl: 54.88em;
$width-home-content-sm: em(428);
$width-home-content-md: em(803);
$width-home-content-xl: em(1184);
